import Joi from 'joi';

const countries = {
	create: {
		name: Joi.string()
			.min(2)
			.required(),
		currency_code: Joi.string()
			.min(2)
			.required(),
		dial_code: Joi.string()
			.min(2)
			.required()
	},
	update: {
		name: Joi.string()
			.min(2)
			.required(),
		currency_code: Joi.string()
			.min(2)
			.required(),
		dial_code: Joi.string()
			.min(2)
			.required()
	}
};

export default countries;
