/* eslint-disable max-len */
<template>
	<div>
		<template v-if="loading">
			<div class="div__loading">
				<Loader />
			</div>
		</template>
		<template v-else>
			<section class="main-grid__row user-profile">
				<div class="user-profile__card">
					<div class="user-profile__section">
						<h5 class="user-profile__heading">Basic Information</h5>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Name:</p>
							<p id="countryName">{{ itemData.name }}</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Currency Code:</p>
							<p id="countryCurrencyCode">{{ itemData.currency_code }}</p>
						</div>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Dial Code:</p>
							<p id="countryDialCode">{{ itemData.dial_code }}</p>
						</div>
					</div>
				</div>
				<div class="user-profile__tabs" ref="tab">
					<ul class="nav nav-tabs" id="myTab" role="tablist">
						<li class="nav-item">
							<a
								class="nav-link active"
								id="profile-tab"
								data-toggle="tab"
								href="#profile"
								role="tab"
								aria-controls="profile"
								aria-selected="true"
								>Edit</a
							>
						</li>
					</ul>
					<div class="tab-content" id="myTabContent">
						<div
							class="tab-pane fade show active user-profile__tabs__section"
							id="profile"
							role="tabpanel"
							aria-labelledby="profile-tab"
						>
							<div class="settings">
								<form method="post" @submit.prevent="update">
									<div class="settings__field">
										<div class="form-row">
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="name">Name</label>
													<input
														:class="[{ 'is-invalid': errors.name }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.name"
														name="name"
														type="text"
														class="form-control"
														id="name"
													/>
												</div>
												<div class="invalid-feedback" id="countryNameError">
													{{ errors.name }}
												</div>
											</div>
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="currencyCode">Currency Code</label>
													<input
														:class="[{ 'is-invalid': errors.currency_code }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.currency_code"
														name="currency_code"
														type="text"
														class="form-control"
														id="currencyCode"
													/>
												</div>
												<div class="invalid-feedback" id="countryCurrencyCodeError">
													{{ errors.currency_code }}
												</div>
											</div>
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="dailCode">Dial Code</label>
													<input
														:class="[{ 'is-invalid': errors.dail_code }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.dial_code"
														name="dial_code"
														type="text"
														class="form-control"
														id="dialCode"
													/>
												</div>
												<div class="invalid-feedback" id="countryDialCodeError">
													{{ errors.dial_code }}
												</div>
											</div>
										</div>
										<div class="settings__primary">
											<button
												class="btn btn__save"
												type="button"
												name="button"
												@click.prevent="update"
												v-bind="{ disabled: loading }"
											>
												Save
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</template>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import form from '../../mixins/form';
import Loader from '../../components/Loader';
import countries from '../../utils/validations/countries';

export default {
	name: 'CountryDetails',
	mixins: [form],
	data() {
		return {
			itemData: {},
			errors: {},
			loading: true
		};
	},
	created() {
		this.fetchCountryDetails(this.$route.params.id);
	},
	watch: {
		getStatus(value) {
			if (value === 'success') {
				this.itemData = this.getCountryDetails;
				this.loading = false;
			}
		}
	},
	computed: {
		...mapGetters(['getStatus', 'getCountryDetails'])
	},
	methods: {
		...mapActions(['updateCountries', 'fetchCountryDetails']),
		update() {
			const data = this.itemData;
			['updated_at', 'created_at', 'id'].forEach((item) => {
				delete data[item];
			});
			this.validate(this.itemData, countries.update);
			if (Object.keys(this.issues).length > 0) {
				this.errors = this.issues;
				return false;
			}
			return this.updateCountries({ id: this.$route.params.id, data });
		}
	},
	components: {
		Loader
	}
};
</script>

<style lang="scss" scoped>
.settings {
	&--wide {
		max-width: 100%;
	}
	&__field {
		margin-top: 30px;
	}
	&__value {
		padding: 0;
		background: #fff;
		input {
			background: #fafafa;
			border: none;
			font-size: 14px;
			height: 36px;
		}
		label {
			font-weight: bold;
			margin-bottom: 0;
		}
	}
	.row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 2rem;
	}
}
.btn__wrapper {
	margin-top: 20px;
	.btn {
		&:not(:last-child) {
			margin-right: 10px;
		}
	}
}
.bold {
	font-weight: bold;
	font-size: 16px;
	margin-right: 5px;
}
</style>
